body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

menu,
ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
    margin: 0;
}

button,
input:where([type=button]),
input:where([type=reset]),
input:where([type=submit]) {
    -webkit-appearance: button;
    background-color: initial;
    background-image: none;
}

button,
select {
    text-transform: none;
}

button,
input,
optgroup,
select,
textarea {
    font-feature-settings: inherit;
    color: inherit;
    font-family: inherit;
    font-size: 100%;
    font-variation-settings: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
}